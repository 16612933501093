/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-13 14:31:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-19 14:04:37
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerPermission = params => axios({
    url: '/api/base/dealer/dealerPermission/dealerHaveAuthority',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerPermission = params => axios({
    url: '/api/base/dealer/dealerPermission/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerPermission = params => axios({
    url: '/api/base/dealer/dealerPermission/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerPermission = params => axios({
    url:'/api/base/dealer/dealerPermission/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerPermission = params => axios({
    url: '/api/dealer_permission/system/dealerPermission/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
